import logo from './assets/logo.png';

function App() {
  return (
    <div className="App">
      <div class="container">
          <div class="logo">
              <img src={logo} alt="Logo"/>
          </div>
          <h1>Site em Construção</h1>
          <p>Estamos trabalhando para trazer algo incrível. Volte em breve!</p>
      </div>
    </div>
  );
}

export default App;
